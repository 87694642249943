import React, { useEffect, useState, useRef } from "react"
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CBreadCrumb, LWrap,CInterviewJumbotron, CQuoteBox, CInterviewMedia, CSectTitle, CSchedule, CStars, CLabelCard, CBtnList
} from "../../../components/_index"
import { recruitArea } from "../../../utils/recruit"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter

  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: '採用情報',
            path: '/recruit/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <CInterviewJumbotron data={{
        date: '2022年01月20日',
        subTitle: '料飲',
        title: '柴 朋也',
        labels: ['仙台ロイヤルパークホテル'],
        img: {
          src: '/assets/images/recruit/article01/kv.png'
        },
        imgSp: {
          src: '/assets/images/recruit/article01/kv__sp.png'
        }
      }} />
      <div className="l_sect u_pt80">
        <LWrap>
          <CQuoteBox>
            <p>2016年に入社後、半年の研修を経て中国料理「桂花苑」に料飲サービスとして配属。<br />2018年4月より1年半の間マルチジョブチームの一員としてフロント・レストラン・宴会サービスと幅広く業務をこなした後、料飲サービスに戻る。<br />現在はオールデイダイニング「シェフズ テラス」と中国料理「桂花苑」の2店舗を担当。</p>
          </CQuoteBox>
          <h2 className="c_headingLv2">社員インタビュー</h2>
          <CInterviewMedia data={[
            {
              img: {
                src: '/assets/images/recruit/article01/img_interview.png',
                alt: ''
              },
              title: <>
               <span>ホテル業界を目指した</span>
               <span>きっかけや入社を</span>
               <span>決めた理由はなんですか？</span>
              </>,
              text: <>
                <p>小さな頃からホテルで働くことに漠然と憧れがあり、旅行先でさまざまなホテルに宿泊するなかで、その思いが確かなものになりました。<br />ロイヤルパークホテルズを知ったのは学生時代。横浜観光をしていた際に立寄った横浜ロイヤルパークホテルで、利用客でないにも関わらず丁寧に観光地を案内してくださったフロントの方の接客が心に残り、自分もこのホテルグループで働きたいと思うようになりました。</p>
                <p>地元仙台、かつ郊外（リゾート地）で勤務したかったこともあり、就職活動は仙台ロイヤルパークホテル一本に絞っておこなっていました。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article01/img_interview02.png',
                alt: ''
              },
              title: <>
               <span>実際に入社した</span>
               <span>仙台ロイヤルパークホテルは</span>
               <span>どんな雰囲気ですか？</span>
              </>,
              text: <>
                <p>社員数がそこまで多くないので、とにかく皆との距離が近い職場です。</p>
                <p>一時マルチジョブチームに配属されていた時には、ランチタイムにレストランに入り、フロントに移動してチェックイン業務を終えたあとラウンジでアフタヌーンティーを提供し夜は宴席でサービスをおこなう…という日々を過ごしていたこともあり、ほとんどの社員が顔見知りになりました。</p>
                <p>マルチに仕事を覚えることは大変でしたが、知識は格段に増えましたし、表の接客はほぼできるようになったことは自分の自信に繋がりました。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article01/img_interview03.png',
                alt: ''
              },
              title: <>
               <span>接客技術の向上以外に、</span>
               <span>現在取り組んでいることがあれば</span>
               <span>教えてください。</span>
              </>,
              text: <>
                <p>現在、レストラン2店舗約40人分の毎月のシフト作成を任されています。皆が残業をすることのないよう多忙日には他セクションにヘルプ依頼をかけたり、スタッフの希望休を出来る限り反映させたり、連休を必ず作ったりと、ここでは話しきれないほど色々なバランスを取りながらシフトを考えています。“しっかり休んで、楽しく仕事に集中する” そんな職場環境を目指しています。</p>
                <p>また今年度ホテルグループ全体で実施している「朝食コンテスト」プロジェクトの仙台代表にも選ばれています。3月には、各ホテルの朝食で提供する自信の一品をお客様に召し上がっていただく最終プレゼンが控えています。グランプリを目指し調理スタッフと試作を重ねるいそがしい日々ですが、グループ全体の大きなイベントに関われることをうれしく思っています。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article01/img_interview04.png',
                alt: ''
              },
              title: <>
               <span>柴さんが考える</span>
               <span>“良い加減”のサービス とは</span>
               <span>どのようなものですか？</span>
              </>,
              text: <>
                <p>“ひたすら観る” これに尽きると思います。接客において、目に見える情報は何よりも大切なもの。ご来店時のファーストインプレッションやさり気ない動きから得た情報を手がかりに自分も行動していきます。 </p>
                <p>もちろん会話も大切です。料飲サービスの魅力は、お客様と話せる時間が長いこと。</p>
                <p>チェックインの際は緊張されるような方も、お食事の間はゆっくりとお過ごしになります。 </p>
                <p>お料理の説明から始まり、仙台の天候や窓から見える景色の話をするうちに場が和み、お客様からたくさんの質問を受けることも非常に多いです。</p>
                <p>ぐっと距離を縮めてみたり、サプライズを盛り上げたり、時には必要以上に話しかけずにお客様同士だけのお時間をお作りすることも。</p>
                <p>正解のない仕事ではありますが、そんなところも私にとっては大きなやりがいとなっています。</p>
              </>,
            },
          ]} />
        </LWrap>
      </div>
      <section className="l_sect u_pb0_pc u_bgGray">
        <LWrap>
          <CSectTitle title={{ ja: "一日のスケジュール", en: "DAILY SCHEDULE" }} />
          <CSchedule
            img={{
              src: '/assets/images/recruit/article01/img_schedule.png',
              alt: ''
            }}
            content={[
              {
                time: '11:00',
                title: <>出勤 / ランチ前ブリーフィング</>,
                text: <>予約詳細の確認、引継ぎ等を行います。</>
              },
              {
                time: '11:30~15:00 ',
                title: <>ランチ営業</>,
                text: <>サービスを行いながら多くのお客様と会話を楽しみます！</>
              },
              {
                time: '15:00~16:00 ',
                title: <>社員食堂で食事</>,
              },
              {
                time: '16:00~17:30 ',
                title: <>ディナー準備</>,
                text: <>サプライズ準備や調理スタッフとの打合せ、予約詳細の確認など行います。</>
              },
              {
                time: '17:30 ',
                title: <>ディナー営業</>,
              },
              {
                time: '19:30',
                title: <>引継ぎ、退勤</>,
                text: <>営業終了時間まで勤務する遅番スタッフに引継ぎをおこない退勤です。</>
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: <>今ある力と<br className="u_sp" />将来へ向けた想い</>, en: "My 6STARS" }} />
          <CStars
            stars={[
              {
                img: {
                  src: '/assets/images/recruit/img_stars_thinking.svg',
                  alt: '人を想う力'
                },
                active: true
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_learning.svg',
                  alt: '学ぶ力'
                }
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_work.svg',
                  alt: '共に働く力'
                },
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_business.svg',
                  alt: 'ビジネスする力'
                }
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_think.svg',
                  alt: '考え抜く力'
                }
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_future.svg',
                  alt: '未来をつくる力'
                },
                active: true
              },
            ]}
            now={{
              title: <>人を想う力</>,
              text: <>お客様のことはもちろんですが、何より大切にしているのは一緒に働く仲間のことです。<br />
              出勤したらまず、その日ホールにいる全員に必ず挨拶＋アルファの一言をかけて回ります。話題はなんでも良いんです。全員とコミュニケーションを取ることを続けているうちに、その日のスタッフの体調やコンディションがわかるようになりました。<br />
              レストランの営業が始まる前にリラックスした空気を作ったり、多忙が予想される日は全員の士気を上げてみたり。一体感ある職場を目指し、仲間を想う声掛けは今後も欠かさず実践していきます。</>
            }}
            future={{
              title: <>未来をつくる力</>,
              text: <>「シェフズテラス」「桂花苑」　オペレーションを一から作り上げた両レストランがリニューアルオープンしてから約1年が経ちますが、チームとしてはまだまだ伸びしろがある状態です。<br />
              今後の目標は、レストランチームの行動指針を作ったり大きな目標を掲げることで更に強いチームを作り、上司をサポートすることです。<br />
              お客様が満足するだけではなく、働くスタッフもいつも笑顔でいられるようなレストランを作っていきたいと思います。</>
            }}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle title={{
            ja: '他の社員の声',
            en: 'OTHER STAFF INTERVIEW'
          }} />
          <CLabelCard align="center" data={[
            {
              img: {
                src: '/assets/images/recruit/img_staff02.png',
                alt: ''
              },
              link: {
                href: '/recruit/article03/'
              },
              labels: ['ザ ロイヤルパーク キャンバス京都二条'],
              title: {
                dataMain: '堤 香奈子',
                sub: <>フロントチーム</>
              },
            },
            {
              img: {
                src: '/assets/images/recruit/img_staff03.png',
                alt: ''
              },
              link: {
                href: '/recruit/article02/'
              },
              labels: ['大阪・神戸エリア'],
              title: {
                dataMain: '橋本 由衣',
                sub: <>管理課</>
              },
            },
          ]} />
          <CBtnList data={[
            {
              iconBefore: true,
              label: '採用情報',
              link: {
                href: '/recruit/',
              },
              icon: "arrowReverse"
            }
          ]} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`